$c-white: #fff;
$c-black: #000;
$c-lightgrey: #ccc;
$c-green: #0F96B3;
$font-georgia: Georgia, Times, "Times New Roman", serif;
$width: 200%;

.halb4-preview {
  display: none;
  font-family: $font-georgia;
  overflow: hidden;

  audio {
    display: none;
  }

  .green {
    color: $c-green;
  }

  //Homepage Variante gross
  &.halb4-wide {
    position: absolute;
    top: 0;
    left: 0;
    width: 418px;
    height: 558px;
  }

  //Unterseite schmale Variante
  &.halb4-narrow {
    position: absolute;
    top: 0;
    left: 0;
    width: 202px;
    height: 596px;

    .canvas {
      .timer, .halb4 {
        font-size: 50px;
        top: 25%;
        left: 16%;
        width: 120px;
      }
    }
  }

  .canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: rgba($c-black, 0.0);
    color: $c-white;

    &.fadeIn {
      animation: canvasFadeIn 1.8s ease-in forwards;
    }
    &.blink {
      animation: canvasFadeIn 0.16s linear;
    }

    .timer {
      font-family: $font-georgia;
      font-size: 72px;
      font-weight: normal;
      color: $c-white;
      position: absolute;
      top: 23%;
      left: 25%;
      //transform: translateX(55%);
      width: 190px;
      margin: 0;
      padding: 0;
      opacity: 0;

      &.fadeIn {
        animation: fadeIn 0.6s ease-in forwards;
      }

      &.active {
        .colon, .second {
          color: $c-green;
        }
      }

      .minute {
        color: $c-lightgrey;
      }
    }

    .halb4 {
      display: none;
      color: $c-lightgrey;
      font-family: $font-georgia;
      font-size: 72px;
      font-weight: normal;
      position: absolute;
      top: 23%;
      left: 25%;
      //transform: translateX(-50%);
      width: 170px;
      margin: 0;
      padding: 0;
    }
  }

  .noise {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: $c-white none no-repeat;
    background-size: cover;
    color: $c-black;
    opacity: 0;

    &.fadeIn {
      animation: fadeIn 2s ease-in 0s forwards;
    }
  }

  //Meldung Lauftext
  .meldung {
    position: absolute;
    top: 62%;
    left: 5%;
    width: 90%;

    &.fadeOut {
      animation: fadeOut .3s ease-in 0s forwards;
    }

    .titel, .untertitel {
      //position: relative;
      //top: 0;
      //left: 0;
      font-family: $font-georgia;
      font-size: 18px;
      line-height: 1.2;
      font-weight: normal;
      width: 100%;
      word-wrap: break-word;
      //hyphens: auto;

      //transform: translateX(100vw);
      transform: translateX($width);
      &.move-out {
        animation: moveOut 2s ease-in 0s forwards;
      }
    }

    //timings: https://matthewlein.com/ceaser/
    .titel {
      color: $c-white;
      margin-bottom: 3px;

      &.move-in {
        //animation: moveIn 2.5s ease-out 0s forwards;
        //easeOutQuint
        animation: moveIn 2.4s cubic-bezier(0.230, 1.000, 0.320, 1.000) 0s forwards;
      }
    }
    .untertitel {
      color: $c-lightgrey;

      &.move-in {
        //animation: moveIn 2.5s ease-out 0.2s forwards;
        //easeOutQuint
        animation: moveIn 2.4s cubic-bezier(0.230, 1.000, 0.320, 1.000) 0.5s forwards;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes canvasFadeIn {
  0% {
    background-color: rgba($c-black, 0);
  }
  100% {
    background-color: rgba($c-black, 0.86);
  }
}

@keyframes moveIn {
  0% {
    //transform: translateX(100vw);
    transform: translateX($width);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes moveOut {
  0% {
    transform: translateX(0);
  }
  100% {
    //transform: translateX(-100vw);
    transform: translateX(-$width);
  }
}